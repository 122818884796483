header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  padding-top: 4rem;
  text-align: left;
  height: 100%;
  position: relative;
}

.description{
  width: 40vw;
}

.spacer {
  padding: 4rem;
}

.greeting {
  text-align: left;
}

.animated-text-greeting {
  animation: fadeIn 1s ease-in-out;
}
.animated-text-header {
  animation: fadeIn 2s ease-in-out;
}
.animated-text-description {
  animation: fadeIn 3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
strong {
  color: var(--color-primary);
}

.word-effect {
  font-size: 5rem;
  font-weight: bolder;
	align-items: center;
	align-content: center;
	text-align: left;
	background: linear-gradient(
		-45deg, 
		var(--color-primary) 25%, 
		var(--color-alt-header) 25%, 
		var(--color-alt-header)  50%, 
		var(--color-primary) 50%, 
		var(--color-primary) 75%, 
		var(--color-alt-header)  75%, 
    var(--color-alt-header) );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 20px 20px;
	background-position: 0 0;
	animation: stripes 2s linear infinite;
}	

@keyframes stripes {
	100% { 
		background-position: 20px 0, 20px 0, 20px 0;
	}
}
/* =============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: left;
}

/* =============== HEADER SOCIALS ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* =============== ME ============== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 20rem;
  height: 15rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* =============== SCROLL DOWN ============== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    padding-top: 2rem;
  }

  .header__container {
    text-align: center;
  }

  .greeting {
    font-size: 0.9rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  .description{
    width: unset;
    padding-top: 2rem;
    font-size: 0.7rem;
  }
  .spacer {
    padding: 0rem;
  }

  .word-effect{
    padding: 1rem;
    font-size: 3.5rem;
    line-height: 4rem;
    text-align: center;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .me {
    width: 94%;
    max-width: 20rem;
    margin-top: 2.5rem;
    justify-content: center;
  }

  .cta {
    margin-top: 1.7rem;
    gap: 1rem;
    padding-top: 2rem;
    justify-content: center;
  }
}
