nav {
  background: var(--color-bg);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s; 
  top: 2rem;
  display: flex;
  gap: 5rem;
  border-radius: 3rem;
  border: 2px solid var(--color-primary);
  backdrop-filter: blur(50px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  font-size: smaller;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
}

nav a:hover {
  background: var(--color-primary);
  color: var(--color-bg);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
}

@media (max-width: 600px){
  nav {
    top: unset;
    bottom: 2rem;
    gap: 0.8rem;
    padding: 0.7rem 1.7rem;
  }
  nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1rem;
  }
  
}

